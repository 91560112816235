<template>
  <div class="swiper_box">

    <swiper ref="mySwiper" class="swiper" :options="swiperOption" @click-slide="swiperClick">
      <swiper-slide v-for="(item, i) in list" :key="i">
        <div class="moudle_item">
          <div class="moudel_cover">
            <img :src="item.img" alt="">
          </div>
          <h3 class="bold">{{item.name}}</h3>
          <div class="tags">
            <span class="tag" v-for="tag in item.tags" :key="tag">{{tag}}</span>
          </div>
          <img class="arrow_icon" src="@/assets/img/aiModule/arrow.png" alt="">
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  props: {
    list: {
      type: Array,
      default: () => ([])
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
          clickable: true
        },
        autoplay: {
          delay: 1000,
          disableOnInteraction: true
        },
        loop: true,
        slidesPerView: 5,
        // centeredSlides: true,
        // 设置前进后退按钮
        navigation: {
          // nextEl: '.button-next',
          // prevEl: '.button-prev'
        }
      }
    }
  },
  methods: {
    swiperClick (i) {
      const l = this.list.length
      const find = this.list[(i - 5) % l]
      console.log(find)
      this.$message.warning('敬请期待')
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.swiper_box {
  width: 100%;
  padding-left: 20px;
  padding-bottom: 50px;
}
.moudle_item {
  position: relative;
  width: 200px;
  height: 272px;
  padding: 20px;
  background-color: #fff;
  .moudel_cover {
    margin-bottom: 20px;
    img {
      width: 100%;
    }
  }
  h3 {
    font-size: 16px;
    color: #1f5ae5;
    margin-bottom: 10px;
  }
  .tags {
    width: calc(100% + 10px);
    display: flex;
    flex-wrap: wrap;
    .tag {
      padding: 2px 10px;
      border: 1px solid #b8b8b8;
      border-radius: 2px;
      color: #b8b8b8;
      margin: 0 10px 10px 0;
    }
  }
  .arrow_icon {
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 18px;
    height: 18px;
  }
}
</style>
