<template>
  <div class="ai_module">
    <template v-if="!isAiChat">
      <AiHead />
      <div class="content">
        <ModuleList />
        <div class="send_box">
          <div class="send_content">
            <div class="send_input">
              <el-input v-model="keyWords" placeholder="Please enter the message content"
                        @keyup.enter.native="toAiMsg"></el-input>
              <span class="send_btn hand" @click="toAiMsg">
                <img src="@/assets/img/aiModule/send_icon.png" alt="">
              </span>

            </div>
            <p class="send_warning">
              本应用发出的信息由AI智能生成，请您自行分辩
            </p>
          </div>
        </div>
      </div>
    </template>
    <AiChat v-else ref="aiChat" @hideAiChat="hideAiChat" />
  </div>
</template>

<script>
import AiHead from '@c/aiHead/index.vue'
import ModuleList from './components/moduleList.vue'
import AiChat from './components/aiChat.vue'
export default {
  props: {},
  components: {
    AiHead,
    ModuleList,
    AiChat
  },
  data () {
    return {
      keyWords: '',
      isAiChat: false
    }
  },
  methods: {
    hideAiChat () {
      this.isAiChat = false
    },
    toAiMsg () {
      if (!this.keyWords) return this.$message.warning('请输入内容')
      if (/^\s+$/g.test(this.keyWords)) return this.$message.warning('请输入有效内容')
      this.isAiChat = true
      this.$nextTick(() => {
        this.$refs.aiChat.openAiChat(this.keyWords)
      })
    }
  },
  // beforeRouteLeave (to, form, next) {
  // alert('离开此页面会终止会话, 是否离开?')
  // this.$confirm('离开此页面会终止会话, 是否离开?', '系统提示', {
  //   confirmButtonText: '离开',
  //   cancelButtonText: '取消',
  //   type: 'warning'
  // }).then(() => {
  //   next()
  // }).catch(() => {
  //   next(false)
  // })
  // next(false)
  // },
  created () {

  }
}
</script>

<style lang="less" scoped>
.ai_module {
  min-height: calc(100vh - 77px);
  background-color: #f5f7fb;
}
.send_box {
  .send_content {
    width: 1160px;
    margin: 0 auto;
    padding-right: 20px;
  }
  .send_input {
    display: grid;
    grid-template-columns: 1fr 68px;
    align-items: center;
    height: 42px;
    border-radius: 4px;
    border: 1px solid #2854cc;
    /deep/.el-input__inner {
      border: 0;
    }
    .send_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 40px;
      background-color: #2854cc;
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
  .send_warning {
    padding: 30px 0;
    color: #979797;
    text-align: center;
  }
}
</style>
