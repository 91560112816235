<template>
  <div class="module_list">
    <div class="doctor">
      <div class="content">
        <ModuleTitle :config="yConfig" />
        <div class="y_list">
          <div class="y_item" v-for="item in yModules" :key="item.name" @click="toMoudel(item)">
            <img :src="item.img" alt="">
            <p class="y_item_name tac">{{item.name}}&gt;&gt;</p>
          </div>
        </div>
      </div>
    </div>
    <div class="research">
      <div class="content">
        <ModuleTitle :config="kConfig" />
        <SwiperModule :list="kModules" />
      </div>
    </div>

  </div>
</template>

<script>
import ModuleTitle from './moduleTitle.vue'
import SwiperModule from './swiper.vue'
export default {
  props: {},
  components: {
    ModuleTitle,
    SwiperModule
  },
  data () {
    return {
      yConfig: {
        title: '医生助手',
        describe: '数据技术驱动、赋能医生成长'
      },
      yModules: [
        {
          name: '药物作用',
          img: require('@/assets/img/aiModule/y_01.png')
        }, {
          name: '报告解读',
          img: require('@/assets/img/aiModule/y_02.png')
        }, {
          name: '辅助诊疗',
          img: require('@/assets/img/aiModule/y_03.png')
        }, {
          name: '文档处理',
          img: require('@/assets/img/aiModule/y_04.png')
        }
      ],
      kModules: [
        {
          name: '医学文献分析',
          img: require('@/assets/img/aiModule/k_01.png'),
          tags: ['分析方法', '选择文献', '输入文献信息']
        }, {
          name: '数据处理分析',
          img: require('@/assets/img/aiModule/k_02.png'),
          tags: ['分析方法', '分析数据']
        }, {
          name: 'SCI润色修改',
          img: require('@/assets/img/aiModule/k_03.png'),
          tags: ['翻译', '润色', '修改', '建议']
        }, {
          name: '实验设计方案',
          img: require('@/assets/img/aiModule/k_04.png'),
          tags: ['研究目标', '研究背景', '可用资源', '试验方法']
        }, {
          name: 'AI智能选刊',
          img: require('@/assets/img/aiModule/k_05.png'),
          tags: ['研究领域', '研究主题', '论文标题', '关键词']
        }, {
          name: '基金论文开题',
          img: require('@/assets/img/aiModule/k_06.png'),
          tags: ['研究方向', '研究领域', '研究目标']
        }, {
          name: '数据统计向导',
          img: require('@/assets/img/aiModule/k_07.png'),
          tags: ['统计目的', '数据类型', '分组情况', '组间关系']
        }
      ],
      kConfig: {
        title: '科研助手',
        describe: '数据技术驱动、赋能医生成长'
      }
    }
  },
  methods: {
    toMoudel () {
      this.$message.warning('敬请期待')
    }
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.doctor {
  background-color: #fff;
}
.content {
  width: 1200px;
  margin: 0 auto;
}
.y_list {
  display: grid;
  grid-template-columns: repeat(4, 168px);
  align-items: center;
  justify-content: space-around;
  padding-bottom: 43px;
  .y_item {
    position: relative;
    width: 168px;
    height: 188px;
    img {
      width: 168px;
      height: 188px;
    }
    .y_item_name {
      position: absolute;
      width: 100%;
      bottom: 30px;
      font-size: 16px;
      color: #1f5ae5;
      font-weight: bold;
    }
  }
}
</style>
